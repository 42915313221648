<template>
 <div class="container">
    <h3>Criar Jogo</h3>
    <Form @submit="createGame" class="row g-3">
      <GameForm :form="form" @update:form="onFormUpdate" />
      <div class="col-12" v-show="!form.anon">
        <h4>Côr</h4>
        <ColourPicker :colours="colours" :value="form.colour" @update:value="form.colour = $event" />
      </div>
      <div class="col-12">
        <button class="btn btn-primary btn-block" :disabled="loading">
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Save</span>
        </button>
      </div>
      
    </Form>
    <div
      v-if="message"
      class="alert"
      :class="successful ? 'alert-success' : 'alert-danger'"
    >
      {{ message }}
    </div>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import TMService from "../services/tm.service";
import ColourPicker from "./ui/ColourPicker.vue";
import GameForm from "./ui/GameForm.vue";

export default {
  name: "New",
  components: {
    Form,
    ColourPicker,
    GameForm
  },
  data() {
    return {
      loading: false,
      message: "",
      form: {
        name: "",
        numPlayers: 4,
        prelude: false,
        venus: false,
        colonies: false,
        fanMade: false,
        turmoil: false,
        turmoilA: false,
        turmoilAV: "Chairman", 
        ptvis:true, 
        trajectory: false,
        moon: false,
        ares: false,
        maxRating: false,
        minLimit: Math.floor((this.$store.state.auth.user.openskill || 0) + 50),
        maxLimit: Math.ceil((this.$store.state.auth.user.openskill || 0) + 50),
        map: "Random All", 
        anon: false,
        colour: this.$store.state.auth.user.favouriteColour || 'red',
      },
      colours: ['red','yellow','blue','green','pink','orange','purple','black']
    };
  },
  computed: {
    checkRange(){
      if(!this.form.maxRating) return false;

      let userRating = (this.$store.state.auth.user.openskill || 0) + 50
      return this.form.maxLimit < userRating || this.form.minLimit > userRating || this.form.minLimit >= this.form.maxLimit ; 
    }
  },
  methods: {
    onFormUpdate(updatedForm) {
      // Only update the parent form if it differs
      if (JSON.stringify(updatedForm) !== JSON.stringify(this.form)) {
        this.form = updatedForm;
      }
    },
    createGame() {
      
      if(this.checkRange) return;

      let options = {
        "anon": this.form.anon,
        "ptvis": this.form.ptvis,
        "venus": this.form.venus,
        "turmoil": this.form.turmoil,
        "colonies": this.form.colonies,
        "turmoilA": this.form.turmoilA,
        "turmoilAV": this.form.turmoilAV,
        "prelude": this.form.prelude,
        "map": this.form.map,
        "tiles": false,
        "name": this.form.name,
        "numPlayers": this.form.numPlayers,
        "league_id": 0,
        "moon": this.form.moon,
        "trajectory": this.form.trajectory,
        "ares": this.form.ares,
        "fanMade": this.form.fanMade
      };

      if(this.form.maxRating)
      {
        options.minRating = this.form.minLimit;
        options.maxRating = this.form.maxLimit;
      }

      let player = {user_id: this.$store.state.auth.user.user_id, colour: this.form.colour}

      TMService.createGame(undefined, options, [player]).then(() => {
        this.$router.push("/home");
      }),
      (error) => {
        this.loading = false;
        this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      };
    }
  },
};
</script>